import { Box, Switch } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Header } from '@/components/shared/Header/Header';
import { AddOns } from '@/components/shared/AddOns';
import { ContentLayout } from '@/components/shared//Layout/ContentLayout';
import { Summary } from '@/components/shared//Summary';
import { Eur } from '@/constants/currencies';
import { Footer } from '@/components/shared/Footer';
import { Perks } from '@/components/shared/Perks';
import { AmountField } from '@/components/shared/fields/AmountField';
import { Segment } from '@/components/shared/Segment';
import { Sticky } from '@/components/shared/Sticky';
import { CurrencyProvider } from '@/contexts/CurrencyContext';
import { usePreferences } from '@/contexts/PreferencesContext';
import { OfferProvider } from '@/contexts/OfferContext';
import { hasProp } from '@/utils/has-prop';

import { useHrParams } from './hooks/useHrParams';
import { Options } from './components/Options';
import { StepHr } from './enums/StepHr';
import { optimisations } from './constants/optimisations';
import { amountFields, fieldsPerDetails } from './constants/fields';
import { chart } from './constants/chart';
import { MIN_BRUTO_1 } from './constants/constants';
import { useBrand } from '@/hooks/useBrand';
import { TYear } from './interfaces/TYear';
import { useState } from 'react';
import { EntryRow } from '../../shared/EntryRow';
import { useCountyTaxRateSelect } from './Croatia.utils';
import { COUNTIES } from './constants/calculator';

const OFFER_HIGHLIGHT = ['bruto1'];

const counties2023 = COUNTIES[2023];

// 1200 e bruto1
// 2023: 858.73
// 2024: 866.23
// 2025:

export const Croatia = ({ isInternal, isOffer }: { isInternal?: boolean; isOffer?: boolean }) => {
  const [year, setYear] = useState<TYear>(new Date().getFullYear() === 2024 ? 2024 : 2025);
  const brand = useBrand();
  const { urlParams, offerValue, state, setField } = useHrParams(brand.brand, isInternal, isOffer, { year });
  const { t } = useTranslation();
  const {
    preferences: { details },
  } = usePreferences();

  const stope = useCountyTaxRateSelect(year);

  const filteredAmountFields = amountFields
    .filter(({ property }) => fieldsPerDetails[details].indexOf(property) !== -1)
    .filter(({ property }) => (hasProp(state.calculated, property) ? state.calculated[property] : undefined));

  return (
    <CurrencyProvider local={Eur} primary={Eur}>
      <OfferProvider value={offerValue}>
        <Header isInternal={isInternal} isOffer={isOffer} urlParams={urlParams} state={state} chart={chart} />
        {stope.find(({ value }) => value === state.values.stopa) ? null : (
          <Box bg="red" color="white" px={8} py={2} textAlign="center">
            {counties2023.find((item) => item?.id === state.values.stopa)?.name} still didn&apos;t define their tax rate
            for 2024.
            <br />
            The deadline is 31st of December 2023
            <br />
            <br />
            <b>The calculation is not correct!</b>
          </Box>
        )}

        <ContentLayout>
          <Sticky>
            <AmountField
              highlighted={isOffer}
              hint={state.defaultState ? (t('common.totalPayHint') as string) : undefined}
              title={t('common.totalPay')}
              description={isOffer ? '' : (t('hr.totalPayInfo') as string)}
              value={state.values.total}
              onChange={(value) => setField('total', value)}
              isInvalid={state.calculated.bruto1 < MIN_BRUTO_1}
              name="total"
            />
          </Sticky>

          <EntryRow
            title="2025 tax laws (unofficial)"
            // eslint-disable-next-line max-len
            href="https://www.index.hr/vijesti/clanak/pogledajte-koliko-ce-rasti-place-u-zagrebu-rijeci-sisku-i-gospicu/2599727.aspx"
          >
            <Box textAlign="right">
              <Switch
                isChecked={year === 2025}
                onChange={(e) => setYear(e.target.checked ? 2025 : 2024)}
                colorScheme="brand"
              />
            </Box>
          </EntryRow>

          <Segment>
            {filteredAmountFields.map((field) => (
              <AmountField
                highlighted={field.highlighted || (isOffer && OFFER_HIGHLIGHT.includes(field.property))}
                title={t(field.title)}
                key={field.property}
                value={hasProp(state.calculated, field.property) ? (state.calculated[field.property] as number) : 0}
                description={field.description && (t(field.description) as string)}
              />
            ))}
            <Summary label="hr.accountPayout" value={state.calculated.racun} />
          </Segment>

          <AddOns state={state} setField={setField} title={t(StepHr.NeoporeziviDodaci)} items={optimisations} />
          <Perks state={state} setField={setField} country="hr" />
          <Options state={state} setField={setField} isOffer={isOffer} year={year} />

          {/* <Summary label="hr.accountPayout" value={state?.calculated.racun} /> */}
          <Footer label="hr.footer" isOffer={isOffer} state={state} chart={chart} />
        </ContentLayout>
      </OfferProvider>
    </CurrencyProvider>
  );
};
