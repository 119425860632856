export enum StepMk {
  Pension = 'mk.pension',
  Health = 'mk.health',
  Employment = 'mk.employment',
  Injury = 'mk.injury',
  Contributions = 'mk.contributions',
  TaxExemption = 'mk.taxExemption',
  Taxable = 'mk.taxable',
  IncomeTax = 'mk.incomeTax',
}
