import { ChartData } from '@/components/shared/Chart';
import { Step } from '@/enums/Step';

import { StepSi } from '../enums/StepSi';
import { IState } from '../interfaces/IState';

export const chart: ChartData<IState> = [
  (state: IState) => [
    ['Iz', 'U', 'Iznos'],
    [Step.TotalCost, Step.Perks, state.calculated.perks],
    [Step.TotalCost, StepSi.MaterialExpenses, state.values.materialExpenses],
    [StepSi.MaterialExpenses, Step.OnAccount, state.values.materialExpenses],
    [Step.TotalCost, Step.Gross, state.calculated.bruttoSalary],
    [Step.TotalCost, StepSi.EmployerTax, state.calculated.employerTax],
    [StepSi.EmployerTax, Step.Government, state.calculated.employerTax],
    [Step.Gross, Step.OnAccount, state.calculated.netto],
    [Step.Gross, Step.Government, state.calculated.employeeTax + state.calculated.incomeTax],
  ],
];
