import { IPropertyOrders } from '@/interfaces/IPropertyOrders';
import { perkDefaults, perkFields, perkVisibility } from '@/constants/perks';
import { OptionOfferState } from '@/contexts/OfferContext';

import { IMkInputData } from '../interfaces/IMkInputData';

export const defaults: IMkInputData = {
  flags: {
    chart: true,
  },
  values: {
    total: 50000,
  },
  options: {
    ...perkDefaults,
  },
};

export const propertyOrderTypes = {
  flags: ['chart'],
  values: ['total'],
  options: [...perkFields],
} as const;

export const offerFields = ['total', ...perkFields] as const;

export const offerFieldDefaults = {
  total: OptionOfferState.ReadOnly,
  ...perkVisibility,
} as const;

export const propertyOrders = propertyOrderTypes as unknown as IPropertyOrders;
