import { useTranslation } from 'react-i18next';

import { SectionHeader } from '@/components/shared/SectionHeader';
import { chart } from '@/components/features/Slovenia/constants/chart';
import { AmountField } from '@/components/shared/fields/AmountField';
import { Step } from '@/enums/Step';
import { Header } from '@/components/shared/Header/Header';
import { ContentLayout } from '@/components/shared/Layout/ContentLayout';
import { Summary } from '@/components/shared/Summary';
import { Segment } from '@/components/shared/Segment';
import { Sticky } from '@/components/shared/Sticky';
import { CurrencyProvider } from '@/contexts/CurrencyContext';
import { Eur } from '@/constants/currencies';
import { Footer } from '@/components/shared/Footer';
import { Perks } from '@/components/shared/Perks';
import { NumberField } from '@/components/shared/fields/NumberField';
import { OfferProvider } from '@/contexts/OfferContext';

import { amountFields, fieldsPerDetails, numberFields } from './constants/fields';
import { useSiParams } from './hooks/useSiParams';
import { StepSi } from './enums/StepSi';
import { useBrand } from '../../../hooks/useBrand';
import { Forbidden } from '../../shared/Forbidden';

export const Slovenia = ({ isInternal, isOffer }: { isInternal?: boolean; isOffer?: boolean }) => {
  const brand = useBrand();
  const { urlParams, offerValue, state, setField } = useSiParams(brand.brand, isInternal, isOffer);
  const { t } = useTranslation();

  if (!isOffer && brand.countries.includes('si') === false) {
    return <Forbidden inline />;
  }

  return (
    <CurrencyProvider local={Eur} primary={Eur}>
      <OfferProvider value={offerValue}>
        <Header isInternal={isInternal} isOffer={isOffer} urlParams={urlParams} state={state} chart={chart} />

        <ContentLayout>
          <Sticky>
            <AmountField
              highlighted={isOffer}
              hint={state.defaultState ? (t('common.totalPayHint') as string) : undefined}
              title={t('si.totalPay')}
              value={state.values.total}
              onChange={(value) => setField('total', value)}
              name="total"
            />
          </Sticky>
          <Segment>
            {numberFields
              .filter(({ field }) => fieldsPerDetails[0].indexOf(field) !== -1)
              .filter(({ field }) => state.values[field] !== undefined)
              .map(({ field, label }) => (
                <NumberField
                  key={field}
                  title={t(label)}
                  value={state.values[field]}
                  onChange={(value) => setField(field, value)}
                  name={field}
                  round={field !== 'yearsBonus'}
                />
              ))}
            <AmountField
              title={t(StepSi.MaterialExpenses)}
              value={state.values.materialExpenses}
              onChange={(value) => setField('materialExpenses', value)}
              name="materialExpenses"
              min={0}
            />
          </Segment>

          <Perks state={state} setField={setField} country="si" />

          <Segment>
            <SectionHeader title={t('common.amounts')} />
            {amountFields
              .filter(({ field }) => fieldsPerDetails[0].indexOf(field) !== -1)
              .filter(({ field }) => state.calculated[field])
              .map(({ field, label, highlighted }) => (
                <AmountField highlighted={highlighted} key={field} title={t(label)} value={state.calculated[field]} />
              ))}
            <Summary label={Step.OnAccount} value={state.calculated.salaryPayout} />
          </Segment>

          {/* <Summary label={Step.OnAccount} value={state.calculated.salaryPayout} /> */}
          <Footer label="si.footer" isOffer={isOffer} state={state} chart={chart} />
        </ContentLayout>
      </OfferProvider>
    </CurrencyProvider>
  );
};
