import { IPropertyOrders } from '@/interfaces/IPropertyOrders';
import { OptionOfferState } from '@/contexts/OfferContext';
import { perkDefaults, perkFields, perkVisibility } from '@/constants/perks';

import { ISiInputData } from '../interfaces/ISiInputData';

export const defaults: ISiInputData = {
  flags: {},
  values: {
    total: 1500,
    brojDjece: 0,
    brojUzdrzavanih: 0,
    yearsBonus: 0,
    childrenCare: 0,
    materialExpenses: 0,
  },
  options: {
    ...perkDefaults,
  },
};

export const propertyOrderTypes = {
  flags: [],
  values: ['total', 'brojDjece', 'brojUzdrzavanih', 'yearsBonus', 'childrenCare', 'materialExpenses'],
  options: [...perkFields],
} as const;

export const offerFields = [
  'total',
  'brojDjece',
  'brojUzdrzavanih',
  'yearsBonus',
  'childrenCare',
  'materialExpenses',
  ...perkFields,
] as const;

export const offerFieldDefaults = {
  total: OptionOfferState.ReadOnly,
  brojDjece: OptionOfferState.Editable,
  brojUzdrzavanih: OptionOfferState.Editable,
  yearsBonus: OptionOfferState.Editable,
  childrenCare: OptionOfferState.Editable,
  materialExpenses: OptionOfferState.Editable,
  ...perkVisibility,
} as const;

export const propertyOrders = propertyOrderTypes as unknown as IPropertyOrders;
