import { Step } from '@/enums/Step';
import { StepSi } from '../enums/StepSi';
import { IAmountField } from '../interfaces/IAmountField';
import { INumberField } from '../interfaces/INumberField';

export const amountFields: Array<IAmountField> = [
  { field: 'netto', label: Step.Net },
  { field: 'taxRelief', label: StepSi.TaxRelief },
  { field: 'baseBruttoSalary', label: StepSi.BaseBruttoSalary },
  { field: 'bruttoSalary', label: Step.Gross },
  { field: 'employeeTax', label: StepSi.EmployeeTax },
  { field: 'taxBase', label: StepSi.TaxBase },
  { field: 'incomeTax', label: StepSi.IncomeTax },
  { field: 'salaryPayout', label: Step.OnAccount, highlighted: true },
  { field: 'employerTax', label: StepSi.EmployerTax },
  { field: 'employerExpense', label: Step.TotalCost },
];

export const numberFields: Array<INumberField> = [
  { field: 'yearsBonus', label: StepSi.YearsBonus },
  { field: 'brojDjece', label: StepSi.Children },
  { field: 'childrenCare', label: StepSi.ChildrenCare },
  { field: 'brojUzdrzavanih', label: StepSi.AdultSupport },
];

export const fieldsPerDetails = [
  [
    'netto',
    'taxRelief',
    'baseBruttoSalary',
    'bruttoSalary',
    'employeeTax',
    'taxBase',
    'incomeTax',
    'salaryPayout',
    'employerTax',
    'employerExpense',
    'yearsBonus',
    'brojDjece',
    'childrenCare',
    'brojUzdrzavanih',
    'materialExpenses',
  ],
];
