import { useParams } from '@/hooks/useParams';

import { useTaxData } from './useTaxData';
import { ITaxData as IMkTaxData } from '../interfaces/ITaxData';
import { offerFieldDefaults, offerFields } from '../constants/defaults';
import { Brand } from '../../../../enums/Brand';

export function useMkParams(brand: Brand, isInternal = false, isOffer = false) {
  return useParams<IMkTaxData>({ brand, isInternal, isOffer, offerFields, offerFieldDefaults, useTaxData });
}
