import { useState, useMemo, useCallback } from 'react';

import { OptionOfferState } from '@/contexts/OfferContext';
import { parseFromUrl, serializeData } from '@/utils/serializer';
import { setFieldFactory } from '@/utils/set-field-factory';
import { useKeys } from '@/contexts/KeysContext';
import { perks as perksList } from '@/constants/perks';
import { isInReadonlyArray } from '@/utils/is-in-readonly-array';

import { ISiInputData } from '../interfaces/ISiInputData';
import { propertyOrders, defaults } from '../constants/defaults';
import { Brand } from '../../../../enums/Brand';

const OZP = 35; // OZP: Obvezni prispevek za zdravstveno zavarovanje

const TAX_LEVELS = [
  { Percent: 50, Fixed: 1929.53, From: 6180 },
  { Percent: 39, Fixed: 1193.08, From: 4291.67 },
  { Percent: 33, Fixed: 484.96, From: 2145.83 },
  { Percent: 26, Fixed: 116.73, From: 729.58 },
  { Percent: 16, Fixed: 0, From: 0 },
];
const TAX_RELIEFS = [
  { From: 1143.03, Amount: 416.67 },
  { From: 1073, Amount: 471.33 },
  { From: 537.22, Amount: 1225.72 },
];
const CHILD_RELIEFS: Record<string, number> = { 1: 209.17, 2: 227.39, 3: 379.26, 4: 531.12, 5: 682.99 }; // ChildReliefs
const CHILD_CARE_RELIEF = 757.91; // ChildCareRelief
const CHILD_OVER_5_RELIEF = 151.87; // ChildOver5Relief

function getTaxRelief(bruto: number): number {
  return TAX_RELIEFS.find((relief) => bruto > relief.From)?.Amount || 0;
}

function getTotalRelief(taxRelief: number, children: number, childrenOver5: number, adults: number): number {
  let relief = taxRelief;
  const childrenSum = children - childrenOver5;

  if (childrenSum > 5) {
    relief += CHILD_RELIEFS[5] + (childrenSum - 5) * CHILD_OVER_5_RELIEF;
  } else if (childrenSum > 0 && childrenSum <= 5) {
    relief += CHILD_RELIEFS[childrenSum];
  }

  if (childrenOver5 > 0) {
    relief += childrenOver5 * CHILD_CARE_RELIEF;
  }

  if (adults > 0) {
    relief += 209.17 * adults;
  }

  return relief;
}

function getTaxPercentage(taxBase: number) {
  const taxLevels = TAX_LEVELS;
  const taxLevel = taxLevels.find((level) => taxBase >= level.From) || taxLevels[0];

  return {
    taxPercentage: taxLevel.Percent,
    taxFixed: taxLevel.Fixed,
    taxVariable: taxBase - taxLevel.From,
  };
}

export function useTaxData(
  brand: Brand,
  offerFields?: ReadonlyArray<string>,
  _calculatorOptions?: Record<string, unknown>,
  urlData: string = window.location.hash.slice(1),
) {
  const DEFAULT_STATE = serializeData(
    propertyOrders,
    defaults,
    () => {
      /* noop */
    },
    brand,
  );

  const { setKey } = useKeys();
  const [inputData, setInputData] = useState(() => parseFromUrl(propertyOrders, defaults, brand, offerFields));
  const [setField] = useState(() => setFieldFactory<ISiInputData>(setInputData));

  const { options } = inputData;
  const perks = options
    ? perksList
        .filter(({ countries }) => isInReadonlyArray('si', countries))
        .map(({ key }) => options[key])
        .filter(({ flag }) => flag)
        .reduce((acc, { value }) => acc + value, 0)
    : 0;

  const calculated = useMemo(() => {
    const baseBruttoSalary = inputData.values.total - perks;
    const bruttoSalary = baseBruttoSalary * (1 + inputData.values.yearsBonus / 100);
    const employeeTax = bruttoSalary * 0.221 + OZP; // 0.10% + 0.14% + 6.36% + 15.50% = 22.10%

    const taxRelief = getTotalRelief(
      getTaxRelief(bruttoSalary),
      inputData.values.brojDjece,
      inputData.values.childrenCare,
      inputData.values.brojUzdrzavanih,
    );

    const taxBase = Math.max(0, bruttoSalary - taxRelief - employeeTax);
    // const taxBar = Math.min(100, Math.floor((taxRelief / bruttoSalary) * 100));
    const { taxPercentage, taxFixed, taxVariable } = getTaxPercentage(taxBase);
    const incomeTax = taxFixed + (taxPercentage / 100) * taxVariable;
    const netto = bruttoSalary - employeeTax - incomeTax;
    const salaryPayout = netto + inputData.values.materialExpenses;
    const employerTax = bruttoSalary * 0.161; // 0.06% + 0.10% + 0.53% + 6.56% + 8.85% = 16.10%
    const employerExpense = bruttoSalary + employerTax + inputData.values.materialExpenses;

    return {
      netto,
      taxRelief,
      baseBruttoSalary,
      bruttoSalary,
      employeeTax,
      taxBase,
      incomeTax,
      salaryPayout,
      employerTax,
      employerExpense,
      perks,
    };
  }, [inputData, perks]);

  const defaultState = useMemo(() => DEFAULT_STATE === urlData || urlData === '', [urlData, DEFAULT_STATE]);

  const state = useMemo(() => ({ ...inputData, calculated, defaultState }), [inputData, calculated, defaultState]);

  const serialize = useCallback(
    (offerState?: Record<string, OptionOfferState>) => {
      return serializeData(propertyOrders, inputData, setKey, brand, 'si', offerState, offerFields);
    },
    [offerFields, inputData, setKey, brand],
  );

  return { state, setInputData, setField, serialize };
}
