export enum StepSi {
  TaxRelief = 'si.taxRelief',
  BaseBruttoSalary = 'si.baseBruttoSalary',
  EmployeeTax = 'si.employeeTax',
  TaxBase = 'si.taxBase',
  IncomeTax = 'si.incomeTax',
  EmployerTax = 'si.employerTax',
  MaterialExpenses = 'si.materialExpenses',
  YearsBonus = 'si.yearsBonus',
  Children = 'si.children',
  ChildrenCare = 'si.childrenCare',
  AdultSupport = 'si.adultSupport',
}
