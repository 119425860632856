import { useParams } from '@/hooks/useParams';

import { ITaxData as ISiTaxData } from '../interfaces/ITaxData';
import { useTaxData } from '../hooks/useTaxData';
import { offerFieldDefaults, offerFields } from '../constants/defaults';
import { Brand } from '../../../../enums/Brand';

export function useSiParams(brand: Brand, isInternal = false, isOffer = false) {
  return useParams<ISiTaxData>({ brand, isInternal, isOffer, offerFields, offerFieldDefaults, useTaxData });
}
