import { StepMk } from '../enums/StepMk';
import { Step } from '@/enums/Step';
import { IAmountFields } from '../interfaces/IAmountFields';

export const amountFields: Array<IAmountFields> = [
  {
    label: StepMk.Pension,
    field: 'pension',
    description: '18.80%',
  },
  {
    label: StepMk.Health,
    field: 'health',
    description: '7.50%',
  },
  {
    label: StepMk.Employment,
    field: 'employment',
    description: '1.20%',
  },
  {
    label: StepMk.Injury,
    field: 'injury',
    description: '0.50%',
  },
  {
    label: StepMk.Contributions,
    field: 'contributions',
  },
  {
    label: StepMk.TaxExemption,
    field: 'taxExemption',
  },
  {
    label: StepMk.Taxable,
    field: 'taxable',
  },
  {
    label: StepMk.IncomeTax,
    field: 'incomeTax',
  },
  { label: Step.Net, field: 'neto', highlighted: true },
];

export const fieldsPerDetails = [
  ['pension', 'health', 'employment', 'injury', 'contributions', 'taxExemption', 'taxable', 'incomeTax', 'neto'],
];
