import { ChartData } from '@/components/shared/Chart';
import { Step } from '@/enums/Step';

import { StepMk } from '../enums/StepMk';
import { IState } from '../interfaces/IState';

export const chart: ChartData<IState> = [
  (state: IState) => [
    ['Iz', 'U', 'износ'],
    [Step.Gross, Step.Perks, state.calculated.perks],
    [Step.Gross, StepMk.IncomeTax, state.calculated.incomeTax],
    [Step.Gross, Step.Net, state.calculated.neto],
    [Step.Gross, StepMk.Contributions, state.calculated.contributions],
    [StepMk.Contributions, StepMk.Pension, state.calculated.pension],
    [StepMk.Pension, Step.Government, state.calculated.pension],
    [StepMk.Contributions, StepMk.Health, state.calculated.health],
    [StepMk.Health, Step.Government, state.calculated.health],
    [StepMk.Contributions, StepMk.Employment, state.calculated.employment],
    [StepMk.Employment, Step.Government, state.calculated.employment],
    [StepMk.Contributions, StepMk.Injury, state.calculated.injury],
    [StepMk.Injury, Step.Government, state.calculated.injury],
    [StepMk.IncomeTax, Step.Government, state.calculated.incomeTax],
  ],
];
