import { useTranslation } from 'react-i18next';

import { chart } from '@/components/features/Macedonia/constants/chart';
import { Step } from '@/enums/Step';
import { Summary } from '@/components/shared/Summary';
import { Segment } from '@/components/shared/Segment';
import { Eur, Mkd } from '@/constants/currencies';
import { Footer } from '@/components/shared/Footer';
import { Perks } from '@/components/shared/Perks';
import { Sticky } from '@/components/shared/Sticky';
import { AmountField } from '@/components/shared/fields/AmountField';
import { CurrencyProvider } from '@/contexts/CurrencyContext';
import { OfferProvider } from '@/contexts/OfferContext';
import { ContentLayout } from '@/components/shared/Layout/ContentLayout';
import { Header } from '@/components/shared/Header/Header';
import { hasProp } from '@/utils/has-prop';

import { amountFields, fieldsPerDetails } from './constants/fields';
import { useMkParams } from './hooks/useMkParams';
import { useBrand } from '../../../hooks/useBrand';
import { Forbidden } from '../../shared/Forbidden';

export const Macedonia = ({ isInternal, isOffer }: { isInternal?: boolean; isOffer?: boolean }) => {
  const brand = useBrand();
  const { urlParams, offerValue, state, setField } = useMkParams(brand.brand, isInternal, isOffer);
  const { t } = useTranslation();

  if (!isOffer && brand.countries.includes('mk') === false) {
    return <Forbidden inline />;
  }

  const filteredAmountFields = amountFields
    .filter(({ field }) => fieldsPerDetails[0].indexOf(field) !== -1)
    .filter(({ field }) => (hasProp(state.calculated, field) ? state.calculated[field] : undefined));

  return (
    <CurrencyProvider local={Mkd} primary={Mkd} secondary={Eur}>
      <OfferProvider value={offerValue}>
        <Header isInternal={isInternal} isOffer={isOffer} urlParams={urlParams} state={state} chart={chart} />

        <ContentLayout>
          <Sticky>
            <AmountField
              highlighted={isOffer}
              hint={state.defaultState ? (t('common.totalPayHint') as string) : undefined}
              title={t(Step.TotalPay)}
              value={state.values.total}
              onChange={(value) => setField('total', value)}
              name="total"
            />
          </Sticky>
          <Segment>
            <AmountField title={t(Step.Gross)} value={state.values.total} />
            {filteredAmountFields.map(({ label, field, highlighted, description }) => (
              <AmountField
                highlighted={highlighted}
                title={t(label)}
                key={field}
                value={hasProp(state.calculated, field) ? state.calculated[field] : 0}
                description={description}
              />
            ))}
            <Summary label={Step.Net} value={state.calculated.neto} />
          </Segment>

          <Perks state={state} setField={setField} country="mk" />

          {/* <Summary label={Step.Net} value={state.calculated.neto} /> */}
          <Footer label="mk.footer" isOffer={isOffer} state={state} chart={chart} />
        </ContentLayout>
      </OfferProvider>
    </CurrencyProvider>
  );
};
