import { useCallback, useMemo, useState } from 'react';

import { perks as perksList } from '@/constants/perks';
import { OptionOfferState } from '@/contexts/OfferContext';
import { parseFromUrl, serializeData } from '@/utils/serializer';
import { setFieldFactory } from '@/utils/set-field-factory';
import { useKeys } from '@/contexts/KeysContext';
import { isInReadonlyArray } from '@/utils/is-in-readonly-array';

import { propertyOrders, defaults } from '../constants/defaults';
import { IMkInputData } from '../interfaces/IMkInputData';
import { Brand } from '../../../../enums/Brand';

export function useTaxData(
  brand: Brand,
  offerFields?: ReadonlyArray<string>,
  calculatorOptions?: Record<string, unknown>,
  urlData: string = window.location.hash.slice(1),
) {
  const DEFAULT_STATE = serializeData(
    propertyOrders,
    defaults,
    () => {
      /* noop */
    },
    brand,
  );

  const { setKey } = useKeys();
  const [inputData, setInputData] = useState(() => parseFromUrl(propertyOrders, defaults, brand, offerFields, urlData));
  const [setField] = useState(() => setFieldFactory<IMkInputData>(setInputData));

  const { options } = inputData;
  const perks = options
    ? perksList
        .filter(({ countries }) => isInReadonlyArray('mk', countries))
        .map(({ key }) => options[key])
        .filter(({ flag }) => flag)
        .reduce((acc, curr) => acc + curr.value, 0)
    : 0;

  const calculated = useMemo(() => {
    const bruto1 = inputData.values.total - perks;

    const pension = Math.round(bruto1 * 0.188);
    const health = Math.round(bruto1 * 0.075);
    const employment = Math.round(bruto1 * 0.012);
    const injury = Math.round(bruto1 * 0.005);
    const contributions = Math.round(pension + health + employment + injury);
    const taxExemption = Math.round(8788);
    const incomeTax = Math.round((bruto1 - taxExemption - contributions) * 0.1);
    const neto = Math.round(bruto1 - contributions - incomeTax);

    return {
      pension,
      health,
      employment,
      injury,
      contributions,
      taxExemption,
      incomeTax,
      neto,
      perks,
    };
  }, [inputData.values.total, perks]);

  const defaultState = useMemo(() => DEFAULT_STATE === urlData || urlData === '', [urlData, DEFAULT_STATE]);

  const state = useMemo(() => ({ ...inputData, calculated, defaultState }), [calculated, inputData, defaultState]);

  const serialize = useCallback(
    (offerState?: Record<string, OptionOfferState>) => {
      return serializeData(propertyOrders, inputData, setKey, brand, 'mk', offerState, offerFields);
    },
    [offerFields, inputData, setKey, brand],
  );

  return { state, setInputData, setField, serialize };
}
